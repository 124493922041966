import { FC } from 'react'
import classNames from 'classnames'
import { City } from 'graphql/types'
import { TSectionProps } from 'pages/home/types'
import { TSearchFields, TSearchField } from './types'
import SectionHeader from 'components/SectionHeader'
import Form from './components/Form'
import styles from './Search.module.scss'

type TProps = {
  cities: City[]
  onFocus?: (e: TSearchField) => void
  onSubmit: (e: TSearchFields) => void
} & TSectionProps

const Search: FC<TProps> = ({
  id,
  className,
  customRef,
  cities,
  onSubmit,
  onFocus,
}) => {
  return (
    <section
      id={id}
      className={classNames(styles.ecPageHomeSearch, className)}
      ref={customRef}
    >
      <SectionHeader
        title={{
          value: (
            <>
              Como é o imóvel que você quer <strong>comprar?</strong>
            </>
          ),
          as: 'h1',
        }}
      />
      <Form cities={cities} onSubmit={onSubmit} onFocus={onFocus} />
    </section>
  )
}

export default Search
