import { FC } from 'react'
import Button from 'components/Button'
import styles from './Services.module.scss'
import Image from 'components/Image'
import classNames from 'classnames'

type TProps = {
  size?: 'medium' | 'big'
  image: {
    src: string
    alt: string
  }
  title: string
  description: string
  button?: {
    label: string
    href: string
    onClick: () => void
  }
}

const Services: FC<TProps> = ({
  size = 'medium',
  image,
  title,
  description,
  button,
}) => {
  const serviceClassName = classNames(styles.ecServices, {
    [styles.ecServicesBig]: size === 'big',
  })

  return (
    <div className={serviceClassName}>
      <div className={styles.ecServicesImage}>
        <Image src={image.src} alt={image.alt} fill />
      </div>
      <div className={styles.ecServicesContent}>
        <div>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        {button ? (
          <Button
            theme="pink"
            element="a"
            href={button.href}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default Services
