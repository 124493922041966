import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import styles from './PreFooter.module.scss'

type TProps = {
  hideBorder?: boolean
}

const PreFooter: FC<PropsWithChildren<TProps>> = ({
  children,
  hideBorder = false,
}) => {
  const preFooterClasses = classNames(styles.ecPreFooter, {
    [styles.hideBorder]: hideBorder,
  })

  return <div className={preFooterClasses}>{children}</div>
}

export default PreFooter
