import { gql } from '@apollo/client'

export default gql`
  query BuyerContextRegions(
    $citySize: Int
    $filters: FilterVolume
    $regionSize: Int
    $resolution: Resolution
  ) {
    buyerContextRegions(
      citySize: $citySize
      filters: $filters
      regionSize: $regionSize
      resolution: $resolution
    ) {
      city
      citySlug
      listingCount
      regions {
        listingCount
        neighborhood
        neighborhoodSlug
        street
        streetSlug
      }
      nearbyLocations {
        neighborhoodSlug
        street
        streetSlug
        distance
      }
      state
      stateSlug
      pointOfInterest {
        name
        nameSlug
        category
        subCategory
      }
    }
  }
`
