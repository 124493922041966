import { FC } from 'react'
import classNames from 'classnames'
import useMediaQuery from 'hooks/useMediaQuery'
import { getServicesListings } from './services'
import { TSectionProps } from '../../../types'
import Services from '../../Services/Services'
import SectionHeader from 'components/SectionHeader'
import { listingsCarouselNavigationEvent } from '../../../events'
import Carousel from 'components/Carousel'
import styles from './Listings.module.scss'

const Listings: FC<TSectionProps> = ({ id, className, customRef }) => {
  const isDeskResolution = useMediaQuery('(min-width: 1024px)')
  const isTabletResolution = useMediaQuery('(min-width: 768px)')
  const isTabletLowResolution = useMediaQuery('(min-width: 481px)')

  let slidesPerPage = 1
  if (isTabletLowResolution) slidesPerPage = 1.2
  if (isTabletResolution) slidesPerPage = 2.2
  if (isDeskResolution) slidesPerPage = 3

  return (
    <section
      id={id}
      className={classNames(styles.ecHomeListings, className)}
      ref={customRef}
    >
      <SectionHeader
        title={{
          value: (
            <>
              Por que todos os <strong>imóveis?</strong>
            </>
          ),
          as: 'h1',
        }}
      />
      <Carousel
        onSlideToPrev={() =>
          listingsCarouselNavigationEvent({ button: 'prev' })
        }
        onSlideToNext={() =>
          listingsCarouselNavigationEvent({ button: 'next' })
        }
        items={[
          ...getServicesListings().map((service) => (
            <Services
              size="big"
              key={service.title}
              image={service.image}
              title={service.title}
              description={service.description}
              button={service.button}
            />
          )),
        ]}
        slidesGap={20}
        centerMode
        slidesPerPage={slidesPerPage}
        pagination={{ hide: true }}
        navigation={{
          hide: isDeskResolution,
          theme: 'outline',
          verticalPosition: 'bottom',
        }}
      />
    </section>
  )
}

export default Listings
