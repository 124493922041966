import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styles from './List.module.scss'

export type TProps = {
  size?: 'small' | 'medium' | 'big'
  orderedList?: boolean
  list: Array<ReactNode>
  className?: string
}

const List: FC<TProps> = ({
  size = 'medium',
  list,
  orderedList,
  className,
}) => {
  const sizeMap = {
    small: {
      className: styles.ecListSmall,
    },
    medium: {
      className: styles.ecListMedium,
    },
    big: {
      className: styles.ecListBig,
    },
  }

  const listContainerClass = classNames(
    className,
    styles.ecList,
    sizeMap[size].className,
    {
      [styles.ecListOrdered]: orderedList,
    },
  )

  const listClassName = classNames({
    [styles.ecListItemOrdered]: orderedList,
    [styles.ecListItemUnordered]: !orderedList,
  })

  const getList = () =>
    list.map((item, index) => {
      const key = `ecListItem_${index}`
      return (
        <li key={key} className={listClassName}>
          {item}
        </li>
      )
    })

  if (orderedList) {
    return <ol className={listContainerClass}>{getList()}</ol>
  }

  return <ul className={listContainerClass}>{getList()}</ul>
}

export default List
