import { FC } from 'react'
import classNames from 'classnames'
import useMediaQuery from 'hooks/useMediaQuery'
import { getServicesBrokers } from './services'
import { TSectionProps } from '../../../types'
import { brokersCarouselNavigationEvent } from '../../../events'
import Services from '../../Services/Services'
import SectionHeader from 'components/SectionHeader'
import Carousel from 'components/Carousel'
import styles from './Brokers.module.scss'

const Brokers: FC<TSectionProps> = ({ id, className, customRef }) => {
  const isDeskResolution = useMediaQuery('(min-width: 1024px)')
  const isTabletResolution = useMediaQuery('(min-width: 768px)')
  const isTabletLowResolution = useMediaQuery('(min-width: 481px)')

  let slidesPerPage = 1.2
  if (isTabletLowResolution) slidesPerPage = 2.2
  if (isTabletResolution) slidesPerPage = 3.2
  if (isDeskResolution) slidesPerPage = 4

  return (
    <section
      id={id}
      className={classNames(styles.ecHomeBrokers, className)}
      ref={customRef}
    >
      <SectionHeader
        title={{
          value: (
            <>
              Por que os <strong>melhores corretores?</strong>
            </>
          ),
          as: 'h1',
        }}
      />
      <Carousel
        onSlideToPrev={() => brokersCarouselNavigationEvent({ button: 'prev' })}
        onSlideToNext={() => brokersCarouselNavigationEvent({ button: 'next' })}
        items={[
          ...getServicesBrokers().map((service) => (
            <Services
              key={service.title}
              image={service.image}
              title={service.title}
              description={service.description}
            />
          )),
        ]}
        slidesGap={20}
        centerMode
        slidesPerPage={slidesPerPage}
        pagination={{ hide: true }}
        navigation={{
          theme: 'outline',
          verticalPosition: 'bottom',
        }}
      />
    </section>
  )
}

export default Brokers
