import { FC } from 'react'
import Radio, { TProps as TRadioProps } from './Radio'
import styles from './RadioGroup.module.scss'

export type TProps = {
  groupClassName?: string
  items: Array<TRadioProps>
}

const RadioGroup: FC<TProps> = ({ groupClassName, items }) => (
  <div className={groupClassName || styles.ecRadioGroupItems}>
    {items.map((item) => (
      <Radio key={item.label} {...item} />
    ))}
  </div>
)

export default RadioGroup
