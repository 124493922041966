import logger from 'lib/logger'
import { toValidNumber } from 'lib/number'
import { TSearchFields } from './components/Sections/Search/types'

type TNameEvent = {
  name: string
}

type TNameValueEvent = {
  name: string
  value?: string
}

export const openEvent = () => logger.action('buyer-landing-page')

export const headerCtaEvent = () => logger.action('buyer-landing-header-cta')

export const tabClickEvent = ({ name }: TNameEvent) =>
  logger.action('buyer-landing-tab', { name })

export const searchFocusEvent = ({ name, value }: TNameValueEvent) =>
  logger.action('buyer-landing-search-focus', {
    name,
    value: name === 'maxPrice' ? toValidNumber(value || '') : value,
  })

export const searchLocationInputMylocation = () =>
  logger.action('buyer-landing-searchlocationinput-mylocation')

export const searchLocationInputMylocationSelect = (
  state?: string,
  city?: string,
) => {
  logger.action('buyer-landing-searchlocationinput-mylocation-select', {
    state,
    city,
  })
}

export const searchLocationInputCitySelect = (city?: string) => {
  logger.action('buyer-landing-searchlocationinput-city-select', { city })
}

export const searchLocationInputSelect = (place: string) => {
  logger.action('buyer-landing-searchlocationinput-select', { place })
}

export const searchLocationInputClear = () => {
  logger.action('buyer-landing-searchlocationinput-clear')
}

export const searchButtonEvent = ({
  citySlug,
  maxPrice,
  minRooms,
  pathname,
}: TSearchFields) =>
  logger.action('buyer-landing-search-cta', {
    citySlug,
    maxPrice: toValidNumber(maxPrice || ''),
    minRooms,
    pathname,
  })

export const searchButtonAboutEvent = () =>
  logger.action('buyer-landing-search-cta-about')

export const brokersCarouselNavigationEvent = ({
  button,
}: {
  button: 'prev' | 'next'
}) =>
  logger.action('buyer-landing-brokers-carousel-navigation', {
    button,
  })

export const listingsCarouselNavigationEvent = ({
  button,
}: {
  button: 'prev' | 'next'
}) =>
  logger.action('buyer-landing-listings-carousel-navigation', {
    button,
  })

export const listingsCarouselCtaEvent = ({ label }: { label: string }) =>
  logger.action('buyer-landing-listings-carousel-cta', {
    label,
  })

export const faqTabEvent = ({ name }: TNameEvent) =>
  logger.action('buyer-landing-faq-tab', { name })

export const faqCtaEvent = () => logger.action('buyer-landing-faq-cta')

export const citiesTabEvent = ({ name }: TNameEvent) =>
  logger.action('buyer-landing-cities-tab', { name })

export const citiesClickEvent = ({ name }: TNameEvent) =>
  logger.action('buyer-landing-cities-click', { name })

export const qevClose = () => logger.action('buyer-landing-qev-close')

export const coverageAreaButtonClickEvent = () =>
  logger.action('buyer-landing-coverage-area-button')
