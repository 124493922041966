import { FC, useState, useEffect, ChangeEvent, FormEvent } from 'react'
import useGeoLocation from 'hooks/useGeoLocation'
import { toValidNumber, toCurrencyNoStyle, toCurrency } from 'lib/number'
import { fetchGeocodeByAddress, parseGeocode } from 'lib/location'
import logger from 'lib/logger'
import { slugify } from 'lib/text'
import { MIN_PRICE } from 'lib/search'
import { City } from 'graphql/types'
import { TSearchFields, TSearchField } from '../types'
import { TItem } from 'components/Typeahead'
import TypeaheadSearch, {
  TSelectType,
} from 'pages/imoveis/components/TypeaheadSearch'
import FormElement from 'components/form/FormElement'
import Input from 'components/form/Input'
import RadioGroup from 'components/form/RadioGroup'
import Button from 'components/Button'
import { LOCALSTORAGE_SEARCH_QEV_MODAL_KEY } from 'components/QEV'
import {
  searchLocationInputMylocation,
  searchLocationInputMylocationSelect,
  searchLocationInputCitySelect,
  searchLocationInputSelect,
  searchLocationInputClear,
} from '../../../../events'
import classNames from 'classnames'
import styles from '../Search.module.scss'

const radios = [
  {
    label: '+1',
    value: '1',
  },
  {
    label: '+2',
    value: '2',
  },
  {
    label: '+3',
    value: '3',
  },
  {
    label: '+4',
    value: '4',
  },
]

type TProps = {
  cities: City[]
  currentValues?: TSearchFields
  onFocus?: (e: TSearchField) => void
  onSubmit: (e: TSearchFields) => void
}

const Form: FC<TProps> = ({
  currentValues = {
    citySlug: '',
    maxPrice: '',
    minRooms: '',
    pathname: '',
  },
  cities,
  onSubmit,
  onFocus,
}) => {
  const { location, getLocation } = useGeoLocation({ update: false })
  const [disableForm, setDisableForm] = useState(false)
  const [disablePrice, setDisablePrice] = useState(false)
  const [shouldGetUserLocation, setShouldGetUserLocation] = useState(false)
  const [search, setSearch] = useState<TSearchFields>(currentValues)
  const changeSearch = ({ name, value }: TSearchField) => {
    setSearch({
      ...search,
      [name]: value,
    })
  }

  const setPathnameByLocation = ({
    citySlug,
    stateSlug,
    streetSlug,
    neighborhood,
    lc,
  }: {
    citySlug?: string
    stateSlug?: string
    streetSlug?: string
    neighborhood?: string
    lc?: string
  }) => {
    if (stateSlug && citySlug) {
      const pathname = `${stateSlug}/${citySlug}${
        neighborhood ? `/${slugify(neighborhood)}` : ''
      }${streetSlug ? `/${streetSlug}` : ''}`
      setSearch({
        ...search,
        pathname,
        citySlug,
        lc,
      })
    }
  }

  const onTypeaheadSearchSelect = (item: TItem, type: TSelectType) => {
    if (type === 'cities') {
      const city = cities.find((city) => city.name === item.label)
      if (city?.nameSlug) {
        setSearch({ citySlug: city.nameSlug, pathname: item.value })
      } else {
        changeSearch({ name: 'pathname', value: item.value })
      }

      searchLocationInputCitySelect(city?.name as string)
    }

    if (type === 'places') {
      setDisableForm(true)
      void fetchGeocodeByAddress(item.value)
        .then((geocode) => {
          if (geocode) {
            const parsedLocation = parseGeocode(geocode)
            setPathnameByLocation(parsedLocation)
            searchLocationInputSelect(item.value)
          }
        })
        .catch((e) => logger.capture(e))
        .finally(() => setDisableForm(false))
    }
  }

  const onUserLocationClick = () => {
    setShouldGetUserLocation(true)
    searchLocationInputMylocation()
    getLocation()
  }

  const onTypeaheadSearchClear = () => {
    changeSearch({ name: 'pathname', value: '' })
    searchLocationInputClear()
  }

  const onFocusInput = (name: string, value: string) => {
    if (onFocus) {
      onFocus({ name, value })
    }
  }

  const onChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const validCurrency = toValidNumber(value) || 0
    const maxPrice = validCurrency ? toCurrencyNoStyle(validCurrency) : ''
    const disable = validCurrency === 0 ? false : validCurrency < MIN_PRICE
    setDisableForm(disable)
    setDisablePrice(disable)
    changeSearch({ name: 'maxPrice', value: maxPrice })
  }

  const onChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target
    const minRooms = checked ? value : ''
    changeSearch({ name: 'minRooms', value: minRooms })
  }

  const onSubmitForm = (e: FormEvent<HTMLElement>) => {
    e.preventDefault()
    if (!disableForm) {
      onSubmit(search)
      localStorage.setItem(
        LOCALSTORAGE_SEARCH_QEV_MODAL_KEY,
        JSON.stringify(search),
      )
    }
  }

  const validPrice = toValidNumber(search.maxPrice || '') !== null

  useEffect(() => {
    if (location?.stateSlug && location?.citySlug && shouldGetUserLocation) {
      const { state, stateSlug, city, citySlug } = location
      setPathnameByLocation({
        stateSlug,
        citySlug,
        lc: `${location?.coordinates?.lng as number}, ${
          location?.coordinates?.lat as number
        }`,
      })
      void searchLocationInputMylocationSelect(state, city)
    }
    setDisableForm(false)
  }, [location, shouldGetUserLocation])

  return (
    <form className={styles.ecPageHomeSearchForm} onSubmit={onSubmitForm}>
      <div
        className={classNames(
          styles.ecPageHomeSearchTypeahead,
          styles.ecPageHomeSearchFormItem,
        )}
      >
        <FormElement
          title={{
            variant: 'label',
            value: 'Localização do imóvel',
            htmlFor: 'typeaheadInput-pageHome',
          }}
          size="small"
        >
          <TypeaheadSearch
            id="pageHome"
            placeholder="Busque por cidade, rua ou bairro"
            optionsClassName={styles.ecPageHomeSearchTypeaheadOptions}
            cities={cities}
            onSelect={onTypeaheadSearchSelect}
            onFocus={() => onFocusInput('citySlug', '')}
            onClear={onTypeaheadSearchClear}
            onSelectLocation={onUserLocationClick}
          />
        </FormElement>
      </div>
      <div className={styles.ecPageHomeSearchFormItem}>
        <FormElement
          title={{
            variant: 'label',
            value: 'Valor máximo do imóvel',
            htmlFor: 'maxPrice',
          }}
          status={
            (toValidNumber(search.maxPrice || '') || 0) < MIN_PRICE &&
            validPrice
              ? 'error'
              : 'default'
          }
          helpText={{
            value: `Valor mínimo de ${toCurrency(MIN_PRICE)}`,
          }}
          size="small"
        >
          <Input
            status={disablePrice ? 'error' : undefined}
            leadingContent={{
              variant: 'text',
              value: 'R$',
            }}
            inputElementProps={{
              id: 'maxPrice',
              name: 'maxPrice',
              maxLength: 11,
              inputMode: 'numeric',
              value: search.maxPrice,
              onChange: onChangePrice,
              onFocus: (e: ChangeEvent<HTMLInputElement>) =>
                onFocusInput('maxPrice', e?.target?.value || ''),
            }}
          />
        </FormElement>
      </div>
      <div className={styles.ecPageHomeSearchFormItem}>
        <FormElement
          fieldsetContainer
          title={{
            variant: 'legend',
            value: 'Quantidade mínima de quartos',
          }}
          size="small"
        >
          <RadioGroup
            items={radios.map((item) => {
              return {
                label: item.label,
                variant: 'button',
                inputElementProps: {
                  value: item.value,
                  name: 'minRooms',
                  onChange: onChangeRadio,
                },
              }
            })}
          />
        </FormElement>
      </div>
      <Button
        className={styles.ecPageHomeSearchFormButton}
        theme="pink"
        type="submit"
        disabled={disableForm}
      >
        Buscar imóvel
      </Button>
    </form>
  )
}

export default Form
