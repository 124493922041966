import { FC } from 'react'
import { TCityProps } from '../types'
import ImageCard from 'components/ImageCard'

type TProps = {
  backgroundImage: string
  onClick?: () => void
} & TCityProps

const City: FC<TProps> = ({
  city,
  citySlug,
  stateSlug,
  backgroundImage,
  onClick,
}) => {
  const href = `/imoveis/${stateSlug}/${citySlug}`
  return (
    <ImageCard
      title={city}
      link={{
        href,
        title: `Ver imóveis: ${city}`,
      }}
      actionText="Ver imóveis"
      background={{
        url: backgroundImage,
        alt: `Cidade: ${city}`,
      }}
      onClick={onClick}
    />
  )
}

export default City
