import { useEffect, useState } from 'react'
import { LOCALSTORAGE_LOCATION_KEY } from 'config'
import {
  fetchGeocodeByCoordinates,
  getStorageCurrentLocation,
  parseGeocode,
  TLocation,
} from 'lib/location'

// More info from Places API: https://developers.google.com/maps/documentation/places/web-service

const useGeoLocation = ({ update }: { update: boolean }) => {
  const [location, setLocation] = useState<TLocation>(
    getStorageCurrentLocation(),
  )
  const [error, setError] = useState('')

  const handleLocation = ({
    coords,
  }: {
    coords: Pick<GeolocationCoordinates, 'latitude' | 'longitude'>
  }) => {
    const coordinates = {
      lat: coords.latitude,
      lng: coords.longitude,
    } as unknown as google.maps.LatLngLiteral

    fetchGeocodeByCoordinates(coordinates)
      .then((geocode) => {
        const parsedGeocode = parseGeocode(geocode)
        setLocation(parsedGeocode)
        localStorage.setItem(
          LOCALSTORAGE_LOCATION_KEY,
          JSON.stringify(parsedGeocode),
        )
      })
      .catch(({ message }: { message: string }) => {
        setError(message)
      })
  }

  const handleError = () => {
    setError('Erro ao recuperar localização.')
  }

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(handleLocation, handleError)
  }

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError('Busca por localização não disponível.')
      return
    }

    if (update) getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  return { location, getLocation, error }
}

export default useGeoLocation
