import { Address } from 'graphql/types'
import logger from 'lib/logger'

export const onClickPreFooterSearchLink = ({
  url,
  label,
  address,
}: {
  url: string
  label: string
  address?: Address
}) =>
  logger.action('pre-footer-link-click', {
    url,
    label,
    currentAddress: address,
  })
