import { FC } from 'react'
import classNames from 'classnames'
import Typography from 'components/Typography'
import Button from 'components/Button'
import { TSectionProps } from '../../../types'
import { searchButtonAboutEvent } from '../../../events'
import styles from './About.module.scss'
import { CLOUDINARY_URL } from 'config'
import Image from 'components/Image'
import useMediaQuery from 'hooks/useMediaQuery'

const About: FC<TSectionProps> = ({ id, className, customRef }) => {
  const isTabletResolution = useMediaQuery('(min-width: 768px)')
  const imageWidth = isTabletResolution ? 'auto' : 800
  return (
    <section
      id={id}
      className={classNames(styles.ecHomeAbout, className)}
      ref={customRef}
    >
      <div className={styles.ecHomeAboutImageWrapper}>
        <Image
          src={`${CLOUDINARY_URL}/w_${imageWidth},q_auto,f_auto/site/photos/home-section-sales.webp`}
          alt="Fachada de uma casa com uma placa de vende-se da EmCasa fincada no gramado."
          fill
          className={styles.ecHomeAboutImage}
          unoptimized
          priority
        />
      </div>
      <div className={styles.ecHomeAboutContent}>
        <Typography
          bold
          as="h2"
          variant="Title"
          className={styles.ecHomeAboutTitle}
        >
          Todos os imóveis, com os melhores corretores
        </Typography>
        <Typography className={styles.ecHomeAboutTitle}>
          Sabemos que a compra de um imóvel tem diferentes propósitos. Por isso
          nossos corretores são capacitados para te ajudar em qualquer fase da
          vida e temos imóveis que se encaixam perfeitamente em cada momento.
        </Typography>
        <Button
          element="a"
          theme="pink"
          href="/imoveis"
          className={styles.ecPageHomeSection}
          onClick={() => searchButtonAboutEvent()}
        >
          Buscar imóvel
        </Button>
      </div>
    </section>
  )
}

export default About
