import { FC } from 'react'
import classNames from 'classnames'
import { BuyerContextRegionsQuery } from 'graphql/types'
import PreFooter from 'components/PreFooter'
import PreFooterSearchLinks from 'components/PreFooter/components/PreFooterSearchLinks'
import { TSectionProps } from 'pages/home/types'
import styles from './PreFooter.module.scss'

type TProps = {
  regions: BuyerContextRegionsQuery | undefined
  isLoading?: boolean
} & TSectionProps

const SectionPreFooter: FC<TProps> = ({
  id,
  className,
  customRef,
  regions,
  isLoading,
}) => {
  return (
    <section
      id={id}
      className={classNames(styles.ecHomePreFooter, className)}
      ref={customRef}
    >
      <PreFooter hideBorder>
        <PreFooterSearchLinks
          title="Veja o que as pessoas estão buscando"
          hideAnotherSection
          itemsPerColumn={10}
          totalColumns={3}
          regions={regions}
          isLoading={isLoading}
        />
      </PreFooter>
    </section>
  )
}

export default SectionPreFooter
