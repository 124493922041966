import { FC, HTMLAttributeAnchorTarget } from 'react'
import Link from 'next/link'
import { IconArrowRight } from 'icons'
import classNames from 'classnames'
import Image from 'components/Image'
import Typography, { TProps as TTypographyProps } from 'components/Typography'
import styles from './ImageCard.module.scss'

type TProps = {
  size?: 'small' | 'medium'
  title: string
  className?: string
  link: {
    href: string
    title?: string
    target?: HTMLAttributeAnchorTarget
  }
  actionText: string
  background: {
    url: string
    alt: string
  }
  onClick?: (label: string, href: string) => void
}

const ImageCard: FC<TProps> = ({
  size = 'medium',
  title,
  link,
  background,
  actionText,
  onClick,
  className,
}) => {
  const sizeMap = {
    small: {
      className: styles.ecImageCardSmall,
      titleVariant: 'TitleSmall',
    },
    medium: {
      className: null,
      titleVariant: 'Title',
    },
  }

  const imageCardClassName = classNames(
    styles.ecImageCard,
    sizeMap[size].className,
    className,
  )
  return (
    <Link
      href={link.href}
      className={imageCardClassName}
      target={link.target}
      title={link.title}
      onClick={() => {
        if (onClick) onClick(title, link.href)
      }}
    >
      <div className={styles.ecImageCardContent}>
        <Typography
          variant={sizeMap[size].titleVariant as TTypographyProps['variant']}
          as="h3"
          bold
          className={styles.ecImageCardTitle}
        >
          {title}
        </Typography>
        <Typography className={styles.ecImageCardAction}>
          {actionText}
          <IconArrowRight
            width={24}
            height={24}
            title="Ícone: seta para direita"
            color="white"
          />
        </Typography>
      </div>
      <div className={styles.ecImageCardImage}>
        <Image
          src={background.url}
          fill
          className={styles.ecImageCardImageElement}
          alt={background.alt}
        />
      </div>
    </Link>
  )
}

export default ImageCard
