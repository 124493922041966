import { CLOUDINARY_URL } from 'config'

export const getServicesBrokers = () => [
  {
    image: {
      alt: 'Fotos de 3 imóveis lado a lado',
      src: `${CLOUDINARY_URL}/homepage/encontramos-seu-imovel`,
    },
    title: 'Encontramos seu imóvel em qualquer lugar',
    description:
      'Caso seu imóvel não esteja na EmCasa, nós encontramos ele em outros portais, ou com outros parceiros.',
  },
  {
    image: {
      alt: 'Corretores da EmCasa em treinamento',
      src: `${CLOUDINARY_URL}/homepage/corretores-treinados`,
    },
    title: 'Corretores treinados',
    description:
      'Ferramentas, treinamentos, incentivos e contratação, tudo para o seu processo de compra e venda ser excelente.',
  },
  {
    image: {
      alt: 'Ferramentas para financiamento',
      src: `${CLOUDINARY_URL}/homepage/assessoria-para-financiamentos`,
    },
    title: 'Conte com a nossa assessoria para financiar seu imóvel',
    description:
      'Encontramos a melhor taxa para você e fazemos todo o relacionamento com o banco.',
  },
  {
    image: {
      alt: 'Assessoria e apoio jurídico para todos os clientes',
      src: `${CLOUDINARY_URL}/homepage/apoio-juridico`,
    },
    title: 'Compre e venda com segurança com nosso apoio jurídico',
    description:
      'Nossos corretores te apoiam juridicamente, além de contar com um time de advogados em toda transação.',
  },
]
