import { Address, BuyerContextRegionsQuery } from 'graphql/types'

type TLocationType = 'city' | 'neighborhood' | 'street'

const getAnotherListingsLocationTitle = (
  locationType: TLocationType,
  { city, neighborhood }: Address,
) => {
  const prefix = 'Outros imóveis em'

  if (locationType === 'city') return `${prefix} ${city as string}`

  return `${prefix} ${neighborhood as string}`
}

const getDynamicListTitle = (
  locationType: TLocationType,
  { city, neighborhood, street }: Address,
) => {
  const prefix = 'Principais'
  if (locationType === 'street') return `Ruas próximas a ${street as string}`
  if (locationType === 'city') return `${prefix} bairros em ${city as string}`
  return `${prefix} ruas em ${neighborhood as string}`
}

const getLocationType = ({
  street,
  streetSlug,
  neighborhood,
  neighborhoodSlug,
}: Address) => {
  if (street && streetSlug) return 'street'

  if (neighborhood && neighborhoodSlug) return 'neighborhood'

  return 'city'
}

const getBaseUrl = (
  locationType: TLocationType,
  { stateSlug, citySlug, neighborhoodSlug }: Address,
) => {
  if (locationType === 'city')
    return `/imoveis/${stateSlug as string}/${citySlug as string}`

  return `/imoveis/${stateSlug as string}/${citySlug as string}/${
    neighborhoodSlug as string
  }`
}

const getAnotherSection = ({
  baseUrl,
  locationType,
  address: { city, neighborhood },
}: {
  baseUrl: string
  locationType: TLocationType
  address: Address
}) => {
  const listingTypes = [
    { label: 'Apartamentos', listingType: 'apartamento' },
    { label: 'Casas', listingType: 'casa' },
  ]

  const listingTypeItems = listingTypes.reduce<
    Array<{ label: string; to: string }>
  >(
    (prev, { label, listingType }) => [
      ...prev,
      {
        label: `${label} com 1 quarto`,
        to: `${baseUrl}/${listingType}/1-quartos`,
      },
      {
        label: `${label} com 2 quartos`,
        to: `${baseUrl}/${listingType}/2-quartos`,
      },
      {
        label: `${label} com 3 quartos`,
        to: `${baseUrl}/${listingType}/3-quartos`,
      },
      {
        label: `${label} com 1 suíte`,
        to: `${baseUrl}/${listingType}?suites=1`,
      },
      {
        label: `${label} com 1 vaga`,
        to: `${baseUrl}/${listingType}/1-vagas`,
      },
      {
        label: `${label} com 2 vagas`,
        to: `${baseUrl}/${listingType}/2-vagas`,
      },
    ],
    [],
  )

  const generalTypeItems = [
    { label: 'Imóveis à venda com varanda ', to: `${baseUrl}/varanda` },
    { label: 'Imóveis à venda com piscina', to: `${baseUrl}/piscina` },
    {
      label: 'Imóveis à venda com churrasqueira',
      to: `${baseUrl}/churrasqueira`,
    },
    { label: 'Imóveis à venda com academia', to: `${baseUrl}/academia` },
    {
      label: 'Imóveis à venda com portaria 24 horas',
      to: `${baseUrl}/portaria-24-horas`,
    },
    {
      label: 'Imóveis à venda com espaço gourmet',
      to: `${baseUrl}/espaco-gourmet`,
    },
  ]

  const anotherListingTitle = getAnotherListingsLocationTitle(locationType, {
    city,
    neighborhood,
  })

  return [
    {
      id: 'anotherSection',
      columnsList: [
        {
          title: anotherListingTitle,
          items: [...listingTypeItems, ...generalTypeItems],
        },
      ],
    },
  ]
}

const getDynamicSection = ({
  buyerContextRegions,
  locationType,
  address: { neighborhoodSlug, neighborhood, street },
  dynamicRegionsLimit,
  dynamicRegionsLabelPrefix,
}: {
  buyerContextRegions: BuyerContextRegionsQuery['buyerContextRegions']
  locationType: TLocationType
  address: Address
  dynamicRegionsLimit?: number
  dynamicRegionsLabelPrefix?: string
}) => {
  const toKey = locationType === 'city' ? 'neighborhoodSlug' : 'streetSlug'
  const labelKey = locationType === 'city' ? 'neighborhood' : 'street'

  const columnsList = buyerContextRegions?.map((context) => {
    const baseUrl = (neighborhood = neighborhoodSlug) => {
      return getBaseUrl(locationType, {
        stateSlug: context?.stateSlug,
        citySlug: context?.citySlug,
        neighborhoodSlug: neighborhood,
      })
    }

    const regionsItems = context?.regions
      ?.slice(0, dynamicRegionsLimit)
      .map((region) => ({
        label: `${dynamicRegionsLabelPrefix || ''}${
          region?.[labelKey] as string
        }`,
        to: `${baseUrl()}/${region?.[toKey] as string}`,
      }))

    const nearbyItems = context?.nearbyLocations
      ?.slice(0, dynamicRegionsLimit)
      .map((region) => ({
        label: `${dynamicRegionsLabelPrefix || ''}${region?.street as string}`,
        to: `${baseUrl(region?.neighborhoodSlug)}/${
          region?.streetSlug as string
        }`,
      }))

    return {
      title: getDynamicListTitle(locationType, {
        city: context?.city,
        neighborhood,
        street,
      }),
      items: locationType === 'street' ? nearbyItems : regionsItems,
    }
  })

  if (!columnsList?.length) return []

  return [
    {
      id: 'dynamicSection',
      columnsList,
    },
  ]
}

export const getSections = ({
  address: {
    stateSlug,
    citySlug,
    neighborhoodSlug,
    streetSlug,
    city,
    state,
    neighborhood,
    street,
  },
  buyerContextRegions,
  hideAnotherSection = false,
  hideDynamicSection = false,
  dynamicRegionsLimit,
  dynamicRegionsLabelPrefix,
}: {
  address: Address
  buyerContextRegions: BuyerContextRegionsQuery['buyerContextRegions']
  hideAnotherSection?: boolean
  hideDynamicSection?: boolean
  dynamicRegionsLimit?: number
  dynamicRegionsLabelPrefix?: string
}) => {
  const hasBaseAddress = city && citySlug && state && stateSlug

  const locationType = getLocationType({
    state,
    stateSlug,
    city,
    citySlug,
    neighborhood,
    neighborhoodSlug,
    street,
    streetSlug,
  })

  const baseUrl = getBaseUrl(locationType, {
    stateSlug,
    citySlug,
    neighborhoodSlug,
  })

  const anotherSection =
    !hideAnotherSection && hasBaseAddress
      ? getAnotherSection({
          baseUrl,
          locationType,
          address: { city, neighborhood, street },
        })
      : []

  const dynamicSection =
    buyerContextRegions && !hideDynamicSection
      ? getDynamicSection({
          buyerContextRegions,
          locationType,
          address: { neighborhoodSlug, neighborhood, street },
          dynamicRegionsLimit,
          dynamicRegionsLabelPrefix,
        })
      : []

  return [...anotherSection, ...dynamicSection]
}
