import { FC, useState } from 'react'
import classNames from 'classnames'
import Tabs from 'components/navigation/Tabs'
import Accordion from 'components/Accordion'
import { TProps as TAccordionItemProps } from 'components/Accordion/components/AccordionItem'
import SectionHeader from 'components/SectionHeader'
import { IconWhatsApp } from 'icons'
import Banner from '../../Banner'
import { TSectionProps } from '../../../types'
import { faqTabEvent, faqCtaEvent } from '../../../events'
import styles from './FAQ.module.scss'
import {
  tabsItems,
  accordionBuyers,
  accordionSellers,
  accordionMortgage,
} from './content'

const FAQ: FC<TSectionProps> = ({ id, className, customRef }) => {
  const [activeTab, setActiveTab] = useState<string>(tabsItems[0].value)
  const accordionItems = {
    buyers: accordionBuyers,
    sellers: accordionSellers,
    mortgage: accordionMortgage,
  } as Record<
    string,
    Array<Pick<TAccordionItemProps, 'size' | 'title' | 'content'>>
  >
  return (
    <section
      id={id}
      className={classNames(styles.ecHomeFAQ, className)}
      ref={customRef}
    >
      <div className={styles.ecHomeFAQSpaceX}>
        <SectionHeader title={{ value: 'Perguntas frequentes' }} />
      </div>
      <div className={styles.ecHomeFAQContent}>
        <div className={styles.ecHomeFAQContainer}>
          <Tabs
            activeKey={activeTab}
            items={tabsItems.map((item) => {
              return {
                label: item.label,
                activeKey: item.value,
                active: activeTab === item.value,
                element: 'button',
                onClick: () => {
                  faqTabEvent({ name: item.label })
                  setActiveTab(item.value)
                },
              }
            })}
          />
          <div className={styles.ecHomeFAQSpaceX}>
            <Accordion items={accordionItems[activeTab]} />
          </div>
        </div>
        <div className={styles.ecHomeFAQSpaceX}>
          <Banner
            theme="green"
            title={{
              value: 'Ficou alguma dúvida?',
              props: {
                variant: 'TitleSmall',
                bold: true,
                as: 'h3',
              },
            }}
            button={{
              label: 'Converse com a gente',
              props: {
                element: 'a',
                href: 'https://api.whatsapp.com/send?phone=551131976638&text=Quero%20falar%20com%20a%20EmCasa%20para%20tirar%20d%C3%BAvidas',
                target: '_blank',
                theme: 'green',
                Icon: {
                  Component: IconWhatsApp,
                  color: 'white',
                  title: 'Ícone: WhatsApp',
                },
                onClick: faqCtaEvent,
              },
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default FAQ
