import { FC, useEffect } from 'react'
import { City } from 'graphql/types'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { getTypeaheadOptions, getPlacesOptions } from 'lib/search'
import Typeahead, { TItem } from 'components/Typeahead'
import Typography from 'components/Typography'
import { useRouter } from 'next/router'
import { parseLocation } from 'lib/location'
import styles from './TypeaheadSearch.module.scss'

export type TSelectType = 'places' | 'cities'
export type TProps = {
  id?: string
  placeholder?: string
  optionsClassName?: string
  cities: Array<City>
  showOpenButton?: boolean
  onFocus: () => void
  onSelect: (item: TItem, type: TSelectType) => void
  onClear: () => void
  onSelectLocation: () => void
  onOpenButtonClick?: () => void
}

const TypeaheadSearch: FC<TProps> = ({
  id = 'pageSearch',
  placeholder = 'Busque por rua ou bairro',
  optionsClassName,
  cities,
  showOpenButton,
  onFocus,
  onSelect,
  onClear,
  onSelectLocation,
  onOpenButtonClick,
}) => {
  const { query } = useRouter()
  const { address } = parseLocation(query.lc as string)
  const BIAS_LOCATION = cities?.find((city) => city?.nameSlug === query?.city)
  const {
    ready,
    suggestions,
    value: typeaheadValue,
    setValue,
    clearCache,
    clearSuggestions,
    init: initPlaces,
  } = usePlacesAutocomplete(
    getPlacesOptions({
      lat: BIAS_LOCATION?.lat as number,
      lng: BIAS_LOCATION?.lng as number,
    }),
  )

  useEffect(() => {
    clearCache()
  }, [query.city])

  return (
    <Typeahead
      id={id}
      showOpenButton={showOpenButton}
      optionsClassName={optionsClassName}
      onOpenSuggestions={() => {
        if (!ready) initPlaces()
      }}
      showSelectionInfo={Boolean(typeaheadValue)}
      defaultValue={address || ''}
      optionsInfo={
        !typeaheadValue && (
          <Typography
            className={styles.ecTypeaheadInfo}
            variant="ParagraphSmall"
            bold
          >
            Atualmente estamos em <strong> {cities.length}</strong> cidades
            espalhadas por todo o Brasil
          </Typography>
        )
      }
      onSelect={(item) => onSelect(item, typeaheadValue ? 'places' : 'cities')}
      onSelectLocation={typeaheadValue ? undefined : onSelectLocation}
      inputProps={{
        placeholder,
        onChange: ({ target }) => setValue(target.value),
        onFocus,
      }}
      onClear={() => {
        setValue('')
        clearSuggestions()
        onClear()
      }}
      onOpenButtonClick={onOpenButtonClick}
      disabledFilter={Boolean(typeaheadValue)}
      options={
        getTypeaheadOptions({
          value: typeaheadValue,
          cities,
          suggestions: suggestions.data,
        }) || []
      }
    />
  )
}

export default TypeaheadSearch
