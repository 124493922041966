import { FC, useState, useMemo } from 'react'
import classNames from 'classnames'
import { IconMap } from 'icons'
import Tabs from 'components/navigation/Tabs'
import Carousel from 'components/Carousel'
import SectionHeader from 'components/SectionHeader'
import useMediaQuery from 'hooks/useMediaQuery'
import City from './components/City'
import { TCityProps } from './types'
import { TSectionProps } from '../../../types'
import {
  citiesTabEvent,
  citiesClickEvent,
  coverageAreaButtonClickEvent,
} from '../../../events'
import styles from './Cities.module.scss'

export const stateNames: Record<string, string> = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goías',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraíma',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
}

const citiesBackgroundImages: Record<string, string> = {
  'belo-horizonte': '/img/home/section-cities-belo-horizonte.webp',
  campinas: '/img/home/section-cities-campinas.webp',
  diadema: '/img/home/section-cities-diadema.webp',
  guarulhos: '/img/home/section-cities-guarulhos.webp',
  maceio: '/img/home/section-cities-maceio.webp',
  maua: '/img/home/section-cities-maua.webp',
  'mogi-das-cruzes': '/img/home/section-cities-mogi-das-cruzes.webp',
  niteroi: '/img/home/section-cities-niteroi.webp',
  osasco: '/img/home/section-cities-osasco.webp',
  'ribeirao-preto': '/img/home/section-cities-ribeirao-preto.webp',
  'rio-de-janeiro': '/img/home/section-cities-rio-de-janeiro.webp',
  'santo-andre': '/img/home/section-cities-santo-andre.webp',
  santos: '/img/home/section-cities-santos.webp',
  'sao-bernardo-do-campo':
    '/img/home/section-cities-sao-bernardo-do-campo.webp',
  'sao-caetano-do-sul': '/img/home/section-cities-sao-caetano-do-sul.webp',
  'sao-jose-dos-campos': '/img/home/section-cities-sao-jose-dos-campos.webp',
  'sao-paulo': '/img/home/section-cities-sao-paulo.webp',
}

export type TProps = {
  cities: Array<TCityProps>
} & TSectionProps

const mainActiveTab = 'todos'

const Cities: FC<TProps> = ({ cities, id, className, customRef }) => {
  const [activeTab, setActiveTab] = useState<string>(mainActiveTab)
  const isDeskResolution = useMediaQuery('(min-width: 1024px)')
  const isTabletResolution = useMediaQuery('(min-width: 768px)')

  const slidesPerPage = () => {
    if (isDeskResolution) return 4
    if (isTabletResolution) return 3.25
    return 1.25
  }

  const tabsItems = useMemo(
    () =>
      cities.reduce(
        (acc, city) => {
          if (!acc.find((item) => item.activeKey === city.stateSlug)) {
            acc.push({
              label: stateNames[city.state],
              activeKey: city.stateSlug,
              active: activeTab === city.stateSlug,
              element: 'button',
              onClick: () => {
                citiesTabEvent({ name: stateNames[city.state] })
                setActiveTab(city.stateSlug)
              },
            })
          }
          return acc
        },
        [
          {
            label: 'Todos',
            active: activeTab === mainActiveTab,
            activeKey: mainActiveTab,
            element: 'button',
            onClick: () => {
              citiesTabEvent({ name: 'Todos' })
              setActiveTab(mainActiveTab)
            },
          },
        ],
      ),
    [cities, activeTab, setActiveTab],
  )

  const carouselItems = cities.filter(
    (item) => activeTab === mainActiveTab || item.stateSlug === activeTab,
  )

  return (
    <section
      id={id}
      className={classNames(styles.ecHomeCities, className)}
      ref={customRef}
    >
      <div className={styles.ecHomeCitiesSpaceX}>
        <SectionHeader
          button={{
            text: 'Ver regiões atendidas',
            href: '/regioes-atendidas',
            onClick: coverageAreaButtonClickEvent,
            Icon: {
              Component: IconMap,
              title: 'Ícone: mapa',
            },
          }}
          title={{
            value: (
              <>
                Encontre casas e apartamentos em{' '}
                <strong>{cities.length} cidades</strong> do Brasil
              </>
            ),
          }}
        />
      </div>
      <Tabs activeKey={activeTab} items={tabsItems} />

      <div
        className={styles.ecHomeCitiesSpaceX}
        data-testid="ecHomeCitiesCarouselWrapper"
      >
        <Carousel
          items={carouselItems.map((item) => {
            const key = `city_${item.citySlug}`
            return (
              <City
                key={key}
                {...item}
                onClick={() => citiesClickEvent({ name: item.city })}
                backgroundImage={
                  citiesBackgroundImages[item.citySlug] ||
                  '/img/home/section-cities-default.webp'
                }
              />
            )
          })}
          slidesGap={32}
          slidesPerPage={slidesPerPage()}
          slidesInfinite={false}
          pagination={{ hide: true }}
          navigation={{ variant: 'inside', theme: 'opacity' }}
        />
      </div>
    </section>
  )
}

export default Cities
