import { FC, ReactNode } from 'react'
import Typography, { TProps as TTypographyProps } from 'components/Typography'
import Button, { TProps as TButtonProps } from 'components/Button'
import styles from './Banner.module.scss'

export type TProps = {
  theme?: 'grey' | 'green'
  title: {
    value: ReactNode
    props?: TTypographyProps
  }
  text?: string
  button?: {
    label: string
    props: TButtonProps
  }
}

const Banner: FC<TProps> = ({ theme = 'grey', title, text, button }) => {
  const themeMap = {
    grey: {
      className: styles.ecHomeBanner,
    },
    green: {
      className: styles.ecHomeBannerGreen,
    },
  }

  return (
    <div className={themeMap[theme].className}>
      <div className={styles.ecHomeBannerContainer}>
        <Typography {...title.props}>{title.value}</Typography>
        {text && <Typography variant="ParagraphBig">{text}</Typography>}
        {button && <Button {...button.props}>{button.label}</Button>}
      </div>
    </div>
  )
}

export default Banner
