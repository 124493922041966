import { CSSProperties, FC } from 'react'
import { Address, BuyerContextRegionsQuery } from 'graphql/types'
import Anchor from 'components/Anchor'
import Loader from 'components/Loader'
import Typography from 'components/Typography'
import styles from './PreFooterSearchLinks.module.scss'
import { getSections } from '../lib'
import { onClickPreFooterSearchLink } from '../events'

export type TProps = {
  address?: Address
  hideAnotherSection?: boolean
  hideDynamicSection?: boolean
  dynamicSectionOptions?: {
    regionsLimit?: number
    itemLabelPrefix?: string
  }
  title?: string
  itemsPerColumn: number
  totalColumns: number
  regions: BuyerContextRegionsQuery | undefined
  isLoading?: boolean
}

const PreFooterSearchLinks: FC<TProps> = ({
  address,
  hideAnotherSection,
  hideDynamicSection,
  title,
  itemsPerColumn,
  totalColumns,
  isLoading,
  regions,
  dynamicSectionOptions,
}) => {
  const {
    state,
    stateSlug,
    city,
    citySlug,
    neighborhood,
    neighborhoodSlug,
    street,
    streetSlug,
  } = address || {}

  const sections = getSections({
    address: {
      state,
      stateSlug,
      city,
      citySlug,
      neighborhood,
      neighborhoodSlug,
      street,
      streetSlug,
    },
    buyerContextRegions: regions?.buyerContextRegions,
    hideAnotherSection,
    hideDynamicSection,
    dynamicRegionsLimit: dynamicSectionOptions?.regionsLimit,
    dynamicRegionsLabelPrefix: dynamicSectionOptions?.itemLabelPrefix || '',
  })

  if (isLoading) return <Loader />

  if (!sections.length) return null

  return (
    <>
      {title && (
        <Typography as="h3" variant="TitleBig" bold>
          {title}
        </Typography>
      )}
      <div className={styles.ecPreFooterSearchLinksContainer}>
        {sections.map((section) => (
          <div
            key={section.id}
            className={styles.ecPreFooterSearchLinksColumnsContainer}
            style={
              {
                '--columns': totalColumns,
              } as CSSProperties
            }
          >
            {section.columnsList?.map((column) => (
              <div
                key={column.title}
                className={styles.ecPreFooterSearchLinksColumn}
              >
                <Typography variant="ParagraphBig" bold>
                  {column.title}
                </Typography>
                <ul
                  className={styles.ecPreFooterSearchLinksContent}
                  style={
                    {
                      '--itemsPerColumn': itemsPerColumn,
                    } as CSSProperties
                  }
                >
                  {column.items?.map(({ label, to }, index) => {
                    const key = `${label}-${to}-${index}`
                    return (
                      <li key={key}>
                        <Anchor
                          href={to}
                          onClick={() =>
                            onClickPreFooterSearchLink({
                              url: to,
                              label,
                              address,
                            })
                          }
                        >
                          {label}
                        </Anchor>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export default PreFooterSearchLinks
