import { RefObject, useEffect, useState } from 'react'

export type TUseIntersectionObserverProps = {
  freezeOnceVisible?: boolean
} & IntersectionObserverInit

const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: TUseIntersectionObserverProps,
) => {
  const [currentEntry, setCurrentEntry] = useState<IntersectionObserverEntry>()

  const frozen = currentEntry?.isIntersecting && freezeOnceVisible

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setCurrentEntry(entry)
  }

  useEffect(() => {
    const node = elementRef?.current
    const hasSupport = Boolean(window.IntersectionObserver)

    if (!hasSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    // eslint-disable-next-line consistent-return
    return (): void => {
      observer.disconnect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

  return {
    entry: currentEntry || null,
    isVisible: Boolean(currentEntry?.isIntersecting),
  }
}

export default useIntersectionObserver
