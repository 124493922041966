import { FC, ReactNode } from 'react'
import { TIconComponent } from 'icons/types'
import Typography, {
  TTypographyElements,
  TVariant,
} from 'components/Typography'
import Button from 'components/Button'
import styles from './SectionHeader.module.scss'

type TButtonConditionalProps =
  | {
      href: string
      onClick?: () => void
    }
  | {
      href?: never
      onClick: () => void
    }

type TConditionalProps =
  | {
      text?: never
      button?: {
        text: string
        Icon?: Omit<TIconComponent, 'width' | 'height' | 'color'>
      } & TButtonConditionalProps
    }
  | {
      text?: string
      button?: never
    }

export type TProps = {
  title: {
    value: ReactNode
    as?: TTypographyElements
    variant?: TVariant
  }
  description?: string
} & TConditionalProps

const SectionHeader: FC<TProps> = ({ title, description, text, button }) => {
  return (
    <div className={styles.ecSectionHeader}>
      <div className={styles.ecSectionHeaderItem}>
        <Typography
          variant={title.variant || 'TitleBig'}
          bold
          as={title.as || 'h2'}
          className={styles.ecSectionHeaderItemTitle}
        >
          {title.value}
        </Typography>

        {description && (
          <Typography variant="TitleSmall">{description}</Typography>
        )}
      </div>
      {text && (
        <Typography className={styles.ecSectionHeaderItem} variant="TitleSmall">
          {text}
        </Typography>
      )}
      {button && (
        <Button
          className={styles.ecSectionHeaderButton}
          onClick={button.onClick}
          element={button.href ? 'a' : 'button'}
          href={button.href}
          Icon={
            button.Icon
              ? ({ ...button.Icon, color: 'grey900' } as Omit<
                  TIconComponent,
                  'width' | 'height'
                >)
              : undefined
          }
        >
          {button.text}
        </Button>
      )}
    </div>
  )
}

export default SectionHeader
