import { CLOUDINARY_URL } from 'config'
import { listingsCarouselCtaEvent } from 'pages/home/events'

export const getServicesListings = () => [
  {
    image: {
      alt: 'Ícone: WhatsApp',
      src: `${CLOUDINARY_URL}/homepage/parceria-quinto-andar`,
    },
    title: 'EmCasa parceira da Rede QuintoAndar',
    description:
      'Com essa parceria, você terá acesso a todos os imóveis do QuintoAndar através da EmCasa. Mais opções para encontrar o imóvel ideal para você.',
    button: {
      label: 'Buscar imóveis',
      href: '/imoveis',
      onClick: () => listingsCarouselCtaEvent({ label: 'Buscar imóveis' }),
    },
  },
  {
    image: {
      alt: 'Ícone: WhatsApp',
      src: `${CLOUDINARY_URL}/homepage/rede-parceira`,
    },
    title: 'Rede parceira com mais de 20 mil corretores indicando imóveis',
    description:
      'Temos uma rede da corretores captando imóveis e crescendo nossa base constantemente, para todo os perfis de compradores.',
    button: {
      label: 'Indicar imóveis',
      href: '/corretor-parceiro/indicar',
      onClick: () => listingsCarouselCtaEvent({ label: 'Indicar imóveis' }),
    },
  },
  {
    image: {
      alt: 'Ícone: WhatsApp',
      src: `${CLOUDINARY_URL}/homepage/anuncie-o-seu-imovel`,
    },
    title: 'Anuncie seu imóvel e alcance mais compradores',
    description:
      'Na EmCasa seu imóvel é divulgado nos principais portais do mercado e redes sociais alcançando milhares de compradores.',
    button: {
      label: 'Anunciar imóvel',
      href: '/vender',
      onClick: () => listingsCarouselCtaEvent({ label: 'Anunciar imóvel' }),
    },
  },
]
