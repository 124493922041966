import { memo, useEffect, useState, useMemo, useRef } from 'react'
import type { NextPage } from 'next'
import { useQuery } from '@apollo/client'
import GET_CITIES from 'graphql/queries/cities'
import GET_BUYER_CONTEXT_REGIONS from 'graphql/queries/buyerContextRegions'
import {
  BuyerContextRegionsQuery,
  BuyerContextRegionsQueryVariables,
  CitiesQuery,
  City,
  Resolution,
} from 'graphql/types'
import { useRouter } from 'next/router'
import { scrollToElement } from 'lib/dom'
import { toValidNumber } from 'lib/number'
import { orderCitiesByVolume } from 'lib/cities'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useEventListener from 'hooks/useEventListener'
import Template from 'templates/Default'
import { IconWhatsApp } from 'icons'
import Button from 'components/Button'
import Tabs from 'components/navigation/Tabs'
import { FAQSchema } from 'config/schemas'
import SectionSearch from './components/Sections/Search'
import { TSearchFields } from './components/Sections/Search/types'
import SectionAbout from './components/Sections/About'
import Brokers from './components/Sections/Brokers'
import Listings from './components/Sections/Listings'
import SectionFAQ from './components/Sections/FAQ'
import SectionCities from './components/Sections/Cities'
import SectionPreFooter from './components/Sections/PreFooter/PreFooter'
import { TCityProps } from './components/Sections/Cities/types'
import classNames from 'classnames'
import styles from './Home.module.scss'
import {
  openEvent,
  headerCtaEvent,
  tabClickEvent,
  searchFocusEvent,
  searchButtonEvent,
} from './events'

export const navItems = [
  {
    label: 'Buscar',
    href: '#buscar',
  },
  {
    label: 'Melhores corretores',
    href: '#melhores-corretores',
  },
  {
    label: 'Todos os imóveis',
    href: '#todos-os-imoveis',
  },
  {
    label: 'Dúvidas',
    href: '#duvidas',
  },
  {
    label: 'Cidades atendidas',
    href: '#cidades',
  },
]

const intersectionObserverOptions = {
  rootMargin: '-50%',
}

let time: ReturnType<typeof setTimeout>

const Home: NextPage = (): JSX.Element => {
  const { data: clientCities } = useQuery<CitiesQuery>(GET_CITIES, {
    ssr: true,
  })
  const queryCities = useMemo(
    () =>
      clientCities?.cities || [
        {
          name: 'São Paulo',
          nameSlug: 'sao-paulo',
          state: 'SP',
          stateSlug: 'sp',
          lat: -23.55052,
          lng: -46.633309,
          __typename: 'City',
          imageFilename: null,
        },
        {
          name: 'Rio de Janeiro',
          nameSlug: 'rio-de-janeiro',
          state: 'RJ',
          stateSlug: 'rj',
          lat: -23.55052,
          lng: -46.633309,
          __typename: 'City',
          imageFilename: null,
        },
      ],
    [clientCities?.cities],
  ) as City[]

  const cities = orderCitiesByVolume(queryCities)

  const { data: clientRegionsData, loading: clientRegionsLoading } = useQuery<
    BuyerContextRegionsQuery,
    BuyerContextRegionsQueryVariables
  >(GET_BUYER_CONTEXT_REGIONS, {
    ssr: true,
    variables: {
      citySize: 3,
      regionSize: 10,
      resolution: Resolution.Neighborhood,
    },
  })

  const [isScrolled, setIsScrolled] = useState(false)
  const tabsClassName = classNames(styles.ecPageHomeTabs, {
    [styles.ecPageHomeTabsShadow]: isScrolled,
  })
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0)
  }
  useEventListener({
    type: 'scroll',
    listener: handleScroll,
  })

  const [activeTab, setActiveTab] = useState<string>(navItems[0].href)
  const setTabTimeout = (callback: () => void) => {
    clearTimeout(time)
    time = setTimeout(callback, 180)
  }
  const tabItems = navItems.map((item) => {
    return {
      label: item.label,
      activeKey: item.href,
      element: 'button',
      onClick: () => {
        const { href } = item
        scrollToElement(
          href.replace('#', ''),
          href === navItems[0].href ? -132 : -72,
        )
        setActiveTab(href)
        tabClickEvent({ name: item.label })
      },
    }
  })
  const searchRef = useRef(null)
  const brokersRef = useRef(null)
  const listingsRef = useRef(null)
  const faqRef = useRef(null)
  const citiesRef = useRef(null)
  const { isVisible: searchIsVisible } = useIntersectionObserver(searchRef, {
    rootMargin: '-120px',
  })
  const { isVisible: brokersIsVisible } = useIntersectionObserver(
    brokersRef,
    intersectionObserverOptions,
  )
  const { isVisible: listingsIsVisible } = useIntersectionObserver(
    listingsRef,
    intersectionObserverOptions,
  )
  const { isVisible: faqIsVisible } = useIntersectionObserver(
    faqRef,
    intersectionObserverOptions,
  )
  const { isVisible: citiesIsVisible } = useIntersectionObserver(
    citiesRef,
    intersectionObserverOptions,
  )
  useEffect(() => {
    let tab = '#'
    if (searchIsVisible) {
      tab = '#buscar'
    } else if (brokersIsVisible) {
      tab = '#melhores-corretores'
    } else if (listingsIsVisible) {
      tab = '#todos-os-imoveis'
    } else if (faqIsVisible) {
      tab = '#duvidas'
    } else if (citiesIsVisible) {
      tab = '#cidades'
    }
    setTabTimeout(() => {
      setActiveTab(tab)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchIsVisible,
    brokersIsVisible,
    listingsIsVisible,
    faqIsVisible,
    citiesIsVisible,
  ])
  const router = useRouter()

  const handleSectionSearchSubmit = ({
    citySlug,
    maxPrice,
    minRooms,
    pathname = '',
    lc,
  }: TSearchFields) => {
    searchButtonEvent({ citySlug, maxPrice, minRooms, pathname })
    const minRoomsURL = minRooms ? `/${minRooms}-quartos` : ''
    void router.push({
      pathname: `/imoveis/${pathname || 'sp/sao-paulo'}${minRoomsURL}`,
      query: maxPrice
        ? {
            precoMaximo: toValidNumber(maxPrice),
            lc,
          }
        : {
            lc,
          },
    })
  }

  useEffect(() => {
    openEvent()
  }, [])

  return (
    <Template
      includeMap
      customClass={{
        main: styles.ecPageHome,
      }}
      head={{
        title: 'EmCasa - Todos os imóveis, com os melhores corretores',
        description:
          'Compre casa ou apartamento com os melhores corretores e conte assessoria financeira e jurídica sem custo. Anuncie seu imóvel conosco e apareça nos principais portais de imóveis.',
        url: '/',
        canonical: 'https://emcasa.com/',
      }}
      headerProps={{
        hideShadow: true,
        borderBottom: true,
        TrailingElement: (
          <Button
            size="small"
            theme="green"
            element="a"
            href="https://api.whatsapp.com/send?phone=551131976638&text=Quero%20falar%20com%20a%20EmCasa%20para%20tirar%20d%C3%BAvidas"
            target="_blank"
            Icon={{
              Component: IconWhatsApp,
              color: 'white',
              title: 'Ícone: WhatsApp',
            }}
            onClick={headerCtaEvent}
          >
            Fale conosco
          </Button>
        ),
      }}
    >
      <script
        type="application/ld+json"
        data-testid="faqSchemaScript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(FAQSchema),
        }}
      />
      <div className={tabsClassName}>
        <div className={styles.ecPageHomeTabsContainer}>
          <Tabs
            scrollAnimation
            scrollOffset={18}
            activeKey={activeTab}
            items={tabItems}
          />
        </div>
      </div>
      <SectionSearch
        id="buscar"
        customRef={searchRef}
        className={styles.ecPageHomeSearch}
        cities={cities}
        onFocus={({ name, value }) => searchFocusEvent({ name, value })}
        onSubmit={handleSectionSearchSubmit}
      />
      <SectionAbout id="servicos" className={styles.ecPageHomeSection} />
      <Brokers
        id="melhores-corretores"
        customRef={brokersRef}
        className={styles.ecPageHomeSection}
      />
      <Listings
        id="todos-os-imoveis"
        customRef={listingsRef}
        className={styles.ecPageHomeSection}
      />
      <SectionFAQ
        id="duvidas"
        customRef={faqRef}
        className={styles.ecPageHomeSection}
      />
      <SectionCities
        id="cidades"
        customRef={citiesRef}
        cities={
          cities?.map((city) => ({
            city: city?.name,
            citySlug: city?.nameSlug,
            state: city?.state,
            stateSlug: city?.stateSlug,
          })) as TCityProps[]
        }
        className={styles.ecPageHomeSection}
      />
      <SectionPreFooter
        id="preFooter"
        className={styles.ecPageHomeSection}
        regions={clientRegionsData}
        isLoading={clientRegionsLoading}
      />
    </Template>
  )
}

export default memo(Home)
