import { FC, InputHTMLAttributes, useId } from 'react'
import classNames from 'classnames'
import styles from './RadioGroup.module.scss'

type TInput = InputHTMLAttributes<HTMLInputElement>
type TSize = 'small' | 'medium' | 'big'
type TVariant = 'radio' | 'button'

export type TProps = {
  label: string
  inputElementProps?: Omit<TInput, 'type'>
  size?: TSize
  variant?: TVariant
}

const Radio: FC<TProps> = ({
  label,
  inputElementProps,
  size = 'medium',
  variant = 'radio',
}) => {
  const sizeMap = {
    small: {
      inputClassName: styles.ecRadioInputSmall,
      labelClassName: styles.ecRadioLabelSmall,
      buttonClassName: styles.ecRadioButtonSmall,
      labelButtonClassName: styles.ecRadioLabelButtonSmall,
    },
    medium: {
      inputClassName: null,
      labelClassName: null,
      buttonClassName: null,
      labelButtonClassName: null,
    },
    big: {
      inputClassName: styles.ecRadioInputBig,
      labelClassName: styles.ecRadioLabelBig,
      buttonClassName: styles.ecRadioButtonBig,
      labelButtonClassName: styles.ecRadioLabelButtonBig,
    },
  }

  const variantMap = {
    radio: {
      className: styles.ecRadio,
      inputClassName: size === 'medium' && styles.ecRadioInput,
      labelClassName: size === 'medium' && styles.ecRadioLabel,
    },
    button: {
      className: size === 'medium' && styles.ecRadioButton,
      inputClassName: styles.ecRadioInputButton,
      labelClassName: size === 'medium' && styles.ecRadioLabelButton,
    },
  }

  const className = classNames(
    variantMap[variant].className,
    variant === 'button' && sizeMap[size].buttonClassName,
  )
  const inputClassName = classNames(
    variantMap[variant].inputClassName,
    variant === 'radio' && sizeMap[size].inputClassName,
  )
  const labelClassName = classNames(
    variantMap[variant].labelClassName,
    variant === 'radio' && sizeMap[size].labelClassName,
    variant === 'button' && sizeMap[size].labelButtonClassName,
  )

  const controlId = inputElementProps?.id
  const elementId = useId()

  return (
    <div className={className}>
      <input
        data-testid={controlId || elementId}
        id={controlId || elementId}
        className={inputClassName}
        type="radio"
        {...inputElementProps}
      />
      <label htmlFor={controlId || elementId} className={labelClassName}>
        {label}
      </label>
    </div>
  )
}

export default Radio
